/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'

import { getLabel, taskStatus } from '@/utils/options';

export var searchOption = [
{
  kname: 'roleId',
  label: '角色名称：',
  component: {
    name: 'selector',
    props: {
      type: 'roles',
      clearable: true,
      placeholder: '请选择角色名称' } } },



{
  kname: 'userName',
  label: '姓名：',
  component: {
    name: 'el-input',
    props: {
      clearable: true,
      placeholder: '请输入姓名或者手机号' } } }];





export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 60 },

  {
    label: '姓名',
    kname: 'userName' },

  {
    label: '角色',
    kname: "roleName" //,
    /*filterFn(v) {
      return v.roleName
    }*/ },

  {
    label: '电话',
    kname: 'phone' },

  {
    label: '创建时间',
    kname: 'createAt' },

  {
    label: '状态',
    kname: 'status',
    component: {
      name: 'c-switch',
      events: {
        change: ctx.changeStatus },

      props: {
        // disabled() {
        //   // return !ctx.hasPermission([58])
        // }
      } } },


  {
    label: '操作',
    kname: 'btns' }];};